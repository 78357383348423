<template>
    <section>
        <div class="container">
            <div class="pt-15 lg:pt-27">
                <GlobalBreadcrumb :breadcrumb="breadcrumb" isLight />

                <v-content option_key="guarantee_heading" />
            </div>
            <div class="w-full lg:w-10/12 mx-auto">
                <ul class="list-disc ml-4 my-12 space-y-2">
                    <v-list option_key="guarantee" :icons="[]" :fields="['link', 'title']" v-slot="{ item }">
                        <li>
                            <v-link :option_key="item['link']" class="inline-block">
                                <v-text
                                    :icons="[]"
                                    :option_key="item['title']"
                                    class="underline text-lg text-blacks-100 font-medium"
                                ></v-text>
                            </v-link>
                            <!-- <v-content
                                :option_key="item['title']"
                                class="underline text-lg text-blacks-100 font-medium"
                                link
                            /> -->
                        </li>
                    </v-list>
                </ul>
            </div>
            <GlobalWrapperContactFooter />
        </div>
    </section>
</template>

<script setup lang="ts">
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Bảo hành'
    }
])
useDirectusCollectionSeo('guarantee', {})
</script>

<style></style>
